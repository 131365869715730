<template>
    <div>
        <van-cell-group>
            <van-cell title="每月租金	" :value="propertyData.prices.primaryPrice"/>
            <van-cell title="每周租金	" :value="propertyData.prices.secondaryPrice"/>
            <van-cell title="类型" :value="propertyData.propertySubType"/>
            <van-cell title="户型">
                <template #label>
                    <span style="margin-right: 30px">BEDROOMS: x{{propertyData.bedrooms}}</span>
                    <span>BATHROOMS: x{{propertyData.bathrooms}}</span>
                </template>
            </van-cell>
            <template v-if="propertyData.lettings">
                <van-cell title="起租时间" v-if="propertyData.lettings.letAvailableDate"
                          :value="propertyData.lettings.letAvailableDate"/>
                <van-cell title="租期类型" v-if="propertyData.lettings.letType" :value="propertyData.lettings.letType"/>
                <van-cell title="家具情况" v-if="propertyData.lettings.furnishType"
                          :value="propertyData.lettings.furnishType"/>
                <van-cell title="押金" v-if="propertyData.lettings.deposit" :value="propertyData.lettings.deposit"/>
            </template>


<!--            <van-cell title="中文描述" label="描述信息">
                <template #label>
                    <div v-html="propertyData.text.cnDescription"></div>
                </template>
            </van-cell>-->

            <van-cell title="户型图" label="户型图">
                <template #label>
                    <van-image v-for="(img, imgIdx) in propertyData.floorplans"
                               style="margin-right: 3px;"
                               width="100"
                               height="100"
                               lazy-load
                               @click="preview(propertyData.floorplans, imgIdx)"
                               :src="img.url || img"
                    />
                </template>
            </van-cell>

            <van-cell title="房源图片集" label="图片集">
                <template #label>
                    <van-image v-for="(img, imgIdx) in propertyData.images"
                               style="margin-right: 3px;"
                               width="100"
                               height="100"
                               lazy-load
                               @click="preview(propertyData.images, imgIdx)"
                               :src="img.url || img"
                    />
                </template>
            </van-cell>

            <van-cell title="VR在线看房" v-if="vrUrl.length>0">
                <template v-if="vrUrl.length==1">
                        <span class="vr-see" @click="seeVr(vrUrl[0].url)">{{vrUrl[0].caption}}</span>
                </template>
                <template v-else  slot="label" >
                 <span v-for="item in vrUrl" class="vr-see" :key="item.url"  style="margin-right: 10px;"
                      @click="seeVr(item.url)">{{item.caption}}</span>
                </template>
            </van-cell>

            <van-cell title="地理位置" label="地理位置">
                <template #label>
                    <map-cmp :address="propertyData.address.displayAddress"
                             :latitude="propertyData.location.latitude"
                             :longitude="propertyData.location.longitude"
                             :map-img-desc="propertyData.mapImgDesc"
                    />
                </template>
            </van-cell>

        </van-cell-group>
    </div>
</template>

<script>
    import {ImagePreview} from 'vant';
    import MapCmp from './mapCmp';

    export default {
        name: "rightmove",
        components: {
            MapCmp
        },
        props: {
            modelStr: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                propertyData: JSON.parse(this.modelStr).propertyData
            }
        },
        computed: {
            vrUrl() {
                let virtuals = this.propertyData.virtualTours
                console.info(virtuals)
                let vrs = []
                if (virtuals && virtuals.length>0) {
                    virtuals.map(it=>{
                        if (it.provider == 'MATTERPORT' || it.provider == 'NONE'){
                            vrs.push({caption: it.caption, url: it.url})
                        }
                    })
                }
                return vrs;
            }
        },
        created() {
            console.info(this.propertyData)
        },
        methods: {
            preview(fileList, idx) {
                let images = []
                fileList.map(it => {
                    images.push(it.url || it)
                })
                ImagePreview({
                    images: images,
                    startPosition: idx
                });
            },
            seeVr(url) {
                window.open(url)
            }
        }
    }
</script>

<style scoped lang="scss">
    .vr-see {
        font-weight: bold;
        color: #1b86ea;
        font-size: 12px;
    }
</style>
