/**
 * <pre>
 *     author    johnny
 *     date      2021-11-23
 *     email     johnny.li@clozhome.com
 *     desc         房源推荐
 * </pre>
 */


import request from '@/utils/request'

export default {
    getByIds(params) {
        return request({
            url: '/house/recommend/getSpiderByIds',
            method: 'get',
            params
        })
    }

}

