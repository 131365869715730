<template>
    <div>
        <van-collapse v-model="activeNames" >
            <van-collapse-item ref="collapseItemRef" :title="row.address" :name="idx" v-for="(row, idx) in rows" :key="'row_'+idx">
                <right-move v-if="row.source=='right_move'" :model-str="row.content"></right-move>
                <realestate v-if="row.source=='realestate'" :model-str="row.content"></realestate>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>

    import $recommendApi from "@/api/recommend";

    import RightMove from './cmp/rightmove'
    import Realestate from './cmp/realestate'

    export default {
        name: "index",
        components: {
            RightMove, Realestate
        },
        data() {
            return {
                rows: [],
                activeNames: [0],
            }
        },
        created() {
            let ids = this.$route.query.ids
            $recommendApi.getByIds({
                ids
            }).then((res) => {
                this.rows = res.data

              setTimeout(()=>{
                this.$refs.collapseItemRef.forEach(it=>{
                  setTimeout(()=>{
                    it.toggle(true)
                  }, 200)
                })
              }, 1000)

            })
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .map-tip {
        color: #1989fa;
    }

</style>
