<template>
    <div>
        <div>
            <van-image v-for="(img, imgIdx) in imgAry"
                       style="margin-right: 3px;"
                       width="100"
                       height="100"
                       lazy-load
                       @click="preview(imgAry, imgIdx)"
                       :src="img"
            />
        </div>
        <div style="display: flex; justify-content: space-between; margin: 10px 0px;">
            <div class="map-tip" @click="mapOpen('mapbox')"> mapbox 地图打开地理位置</div>
            <div class="map-tip" @click="mapOpen('google')"> google 地图打开地理位置</div>
        </div>
    </div>
</template>

<script>
    import {ImagePreview} from 'vant';
    export default {
        name: "MapCmp",
        props: {
            address: {
                type: String,
                default: ''
            },
            longitude:{
                type: String,
                default: ''
            },
            latitude: {
                type: String,
                default: ''
            },
            mapImgDesc: {
                type: String,
                default: ''
            }
        },
        data() {
            return {}
        },
        created() {



        },
        computed: {
            /**
             * 从富文本中截取图片
             * @returns {Array}
             */
            imgAry() {
                let srcAry = []
                if(this.mapImgDesc) {
                    try {
                        console.info(this.mapImgDesc)
                        //匹配图片（g表示匹配所有结果i表示区分大小写）
                        let imgReg = /<img.*?(?:>|\/>)/gi;
                        //匹配src属性
                        let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
                        let arr = this.mapImgDesc.match(imgReg);
                        for (let i = 0; i < arr.length; i++) {
                            srcAry.push(arr[i].match(srcReg)[1])
                        }
                    }catch (e) {
                        console.error('图片截图解析错误')
                    }
                }
                return srcAry
            }
        },
        methods: {
            mapOpen(type) {
                let address = this.address
                let longitude = this.longitude
                let latitude = this.latitude
                if (type == 'mapbox') {
                    this.$router.push({
                        path: '/recommend/mapbox',
                        query: {
                            address: address,
                            longitude: longitude,
                            latitude: latitude,
                        }
                    })
                } else {
                    //google
                    const point = [longitude, latitude]
                    window.open('https://www.google.com/maps/dir/' + encodeURIComponent(address) + '//@' + point.join(',') + 'z/')
                }
            },
            preview(fileList, idx) {
                ImagePreview({
                    images: fileList,
                    startPosition: idx
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .map-tip{
        color: rgb(27,134,234);
    }
</style>
